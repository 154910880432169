<template>
  <section class="news_index page_header_space">
    <div class="top w1200">
      <div class="title">
        <h3 class="txt-bold">{{ newsPageType || '全部' }}</h3>
        <img src="@/statics/img/index/icon_title2.svg" alt="">
      </div>

      <div class="filter">
        <!-- 透過選擇日期區間篩選 -->
        <form action="" method="" id="time_filter" name="time_filter">
          <div class="time_filter">
            <label for="date_from" class="date_from">
              <date-pick
                v-model="params.start_date"
                :inputAttributes="{readonly: true}"
                :isDateDisabled="isFutureDate"
                :selectableYearRange="{from: 2015, to: 2025}"
              ></date-pick>
            </label>
            <span class="from_to">~</span>
            <label for="date_to" class="date_to">
              <date-pick
                v-model="params.end_date"
                :inputAttributes="{readonly: true}"
                :isDateDisabled="isFutureDate"
                :selectableYearRange="{from: 2015, to: 2025}"
              ></date-pick>
            </label>
          </div>
        </form>
        <!-- 透過關鍵字篩選 -->
        <form action="" method="" id="keyword_filter" @submit.prevent>
          <p class="small txt-bold">搜尋標題/內文</p>
          <div class="input_wrapper">
            <input
              id="keyword"
              name="keyword"
              type="text"
              class="p"
              v-model.trim="placeHolder"
              @keyup.enter="updateKeyword"
            >
            <button
              type="button"
              class="small"
              @click="updateKeyword"
            >查詢</button>
          </div>
        </form>
      </div>
    </div>

    <div class="bottom w1200">
      <!-- table area -->
      <div class="table_box">
        <table class="txt-center">
          <thead>
            <tr>
              <th class="min130">
                <p>{{ tableHead1 }}</p>
              </th>
              <th class="min130">
                <p>{{ tableHead2 }}</p>
              </th>
              <th class="td-main txt-left">
                <p>{{ tableHead3 }}</p>
              </th>
            </tr>
          </thead>
          <tbody id="table_data">
            <!-- 至頂文章 -->
            <!-- <tr v-for="row in tableDataPerPage" :key="row.id">
              <td :data-title="tableHead1" class="min130 nowrap txt-red">
                <p>{{ row.date }}</p>
              </td>
              <td :data-title="tableHead2" class="min130 nowrap td-category">
                <p><span>{{ row.category }}</span></p>
              </td>
              <td :data-title="tableHead3" class="td-main td-link txt-left">
                <p><router-link :to="`${$route.path}/article`">{{ row.title }}</router-link></p>
              </td>
            </tr> -->
            <tr v-for="row in tableData" :key="row.uuid">
              <td
                :data-title="tableHead1"
                class="min130 nowrap"
                :class="{
                  'txt-red': parseInt(row.top, 10) === 1,
                  'txt-light_green': parseInt(row.top, 10) !== 1
                }"
              >
                <p>{{ postDate(row) }}</p>
              </td>
              <td :data-title="tableHead2" class="min130 nowrap td-category">
                <p><span>{{ row.sys_name }}</span></p>
              </td>
              <td :data-title="tableHead3" class="td-main td-link txt-left">
                <p><router-link :to="`${$route.path}/article?news_id=${row.uuid}`"
                >{{ row.title }}</router-link></p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <Pagination
        :pageButton="pageButton"
        :page="params.page"
        :totalPage="totalPage"
        @updatePage="updatePage"
      />
      <!-- <PaginationComputed
        :pageData="tableDataByKeyword"
        :perPage="perPage"
        :nowPage="nowPage"
        :pageButton="pageButton"
        @updateNowPage="updateNowPage"
      /> -->
    </div>

  </section>
</template>

<script>
import {
  mapState, mapMutations, mapGetters, mapActions,
} from 'vuex';
import { getSysVars, articleIndex } from '@/lib/http';
import { defaultDateChosen } from '@/lib/public';
// import PaginationComputed from '@/components/PaginationComputed.vue';
import Pagination from '@/components/Pagination.vue';

import _ from 'lodash';
import moment from 'moment';
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';

export default {
  name: 'NewsIndex',
  data() {
    return {
      /** Static Data */
      tableHead1: '發佈日期',
      tableHead2: '類別',
      tableHead3: '標題',
      pageButton: 5,
      placeHolder: '',

      /** Search Params */
      sys_var_id: null,
      params: {
        start_date: '',
        end_date: '',
        keyword: '',
        limit: 10,
        page: 1,
      },

      /** Axios Data */
      totalPage: 1,
      tableData: [
        // {
        //   id: 1,
        //   date: '2020-01-01',
        //   category: '相關公文',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!
        // 本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)
        // 將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 2,
        //   date: '2020-02-02',
        //   category: '相關公文',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },
        // {
        //   id: 3,
        //   date: '2020-03-03',
        //   category: '相關公文',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日
        // )將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 4,
        //   date: '2020-04-04',
        //   category: '相關公文',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },
        // {
        //   id: 5,
        //   date: '2020-05-05',
        //   category: '相關公文',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本
        // 會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 6,
        //   date: '2020-06-06',
        //   category: '相關公文',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },
        // {
        //   id: 7,
        //   date: '2020-07-07',
        //   category: '相關公文',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!
        // 本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 8,
        //   date: '2020-08-08',
        //   category: '相關公文',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },

        // {
        //   id: 9,
        //   date: '2020-09-01',
        //   category: '論文刊物',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!
        // 本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 10,
        //   date: '2020-10-02',
        //   category: '論文刊物',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },
        // {
        //   id: 11,
        //   date: '2020-11-03',
        //   category: '論文刊物',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!
        // 本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 12,
        //   date: '2020-12-04',
        //   category: '論文刊物',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },
        // {
        //   id: 13,
        //   date: '2020-01-05',
        //   category: '論文刊物',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!
        // 本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 14,
        //   date: '2020-02-06',
        //   category: '論文刊物',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },
        // {
        //   id: 15,
        //   date: '2020-03-07',
        //   category: '論文刊物',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!
        // 本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 16,
        //   date: '2020-04-08',
        //   category: '論文刊物',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },

        // {
        //   id: 17,
        //   date: '2020-05-01',
        //   category: '健保資訊',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!
        // 會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 18,
        //   date: '2020-06-02',
        //   category: '健保資訊',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },
        // {
        //   id: 19,
        //   date: '2020-07-03',
        //   category: '健保資訊',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!
        // 本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 20,
        //   date: '2020-08-04',
        //   category: '健保資訊',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },
        // {
        //   id: 21,
        //   date: '2020-09-05',
        //   category: '健保資訊',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!
        // 本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 22,
        //   date: '2020-10-06',
        //   category: '健保資訊',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },
        // {
        //   id: 23,
        //   date: '2020-11-07',
        //   category: '健保資訊',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!
        // 本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 24,
        //   date: '2020-12-08',
        //   category: '健保資訊',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },

        // {
        //   id: 25,
        //   date: '2020-01-01',
        //   category: '新聞焦點',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!
        // 本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 26,
        //   date: '2020-02-02',
        //   category: '新聞焦點',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },
        // {
        //   id: 27,
        //   date: '2020-03-03',
        //   category: '新聞焦點',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!
        // 本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 28,
        //   date: '2020-04-04',
        //   category: '新聞焦點',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },
        // {
        //   id: 29,
        //   date: '2020-05-05',
        //   category: '新聞焦點',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!
        // 本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 30,
        //   date: '2020-06-06',
        //   category: '新聞焦點',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },
        // {
        //   id: 31,
        //   date: '2020-07-07',
        //   category: '新聞焦點',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!
        // 本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 32,
        //   date: '2020-08-08',
        //   category: '新聞焦點',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },

        // {
        //   id: 33,
        //   date: '2020-09-01',
        //   category: '活動花絮',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!
        // 本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 34,
        //   date: '2020-10-02',
        //   category: '活動花絮',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },
        // {
        //   id: 35,
        //   date: '2020-11-03',
        //   category: '活動花絮',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!
        // 本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 36,
        //   date: '2020-12-04',
        //   category: '活動花絮',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },
        // {
        //   id: 37,
        //   date: '2020-01-05',
        //   category: '活動花絮',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!
        // 本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 38,
        //   date: '2020-02-06',
        //   category: '活動花絮',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },
        // {
        //   id: 39,
        //   date: '2020-03-07',
        //   category: '活動花絮',
        //   title: '本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!
        // 本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!本會預計3/1(日)將轉換系統網站!',
        //   content: '本會預計3/1(日)將轉換系統網站!',
        // },
        // {
        //   id: 40,
        //   date: '2020-04-08',
        //   category: '活動花絮',
        //   title: '本會預計3/1(日)將轉換系統網站!',
        //   content: '測試',
        // },
        // {
        //   id: 41,
        //   date: '2020-04-08',
        //   category: '活動花絮',
        //   title: '123',
        //   content: '123',
        // },
        // {
        //   id: 42,
        //   date: '2020-04-08',
        //   category: '活動花絮',
        //   title: '測試',
        //   content: '測試',
        // },
      ],
      // perPage: 10,
      // nowPage: 1,
    };
  },
  created() {
    /**
     * 預設時間區間
     */
    this.params.start_date = '2015-01-01';
    // this.params.start_date = defaultDateChosen().start;
    this.params.end_date = defaultDateChosen().end;
    /**
     * url參數覆蓋預設篩選條件
     */
    if (this.$route.query.start_date) {
      this.params.start_date = this.$route.query.start_date;
    }
    if (this.$route.query.end_date) {
      this.params.end_date = this.$route.query.end_date;
    }
    if (this.$route.query.keyword) {
      this.params.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.num) {
      this.params.page = parseInt(this.$route.query.num, 10);
    }
    /**
     * url參數覆蓋預設篩選條件
     */
    if (this.$route.query.start_date) {
      this.params.start_date = this.$route.query.start_date;
    }
    if (this.$route.query.end_date) {
      this.params.end_date = this.$route.query.end_date;
    }
    if (this.$route.query.keyword) {
      this.params.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.num) {
      this.params.page = parseInt(this.$route.query.num, 10);
    }
    /**
     * 更新state
     */
    this.updateNewsType(this.$route.params.type);
    /**
     * 篩選資料
     */
    getSysVars()
      .then((res) => {
        this.setSysVars(res);
      })
      .then(() => {
        this.filterById(this.params.page, true);
      });
  },
  watch: {
    /**
     * state改變時，根據條件重撈資料
     */
    newsPageType() {
      // console.log('change type:', this.newsPageType);
      this.filterById(this.params.page);
    },
  },
  computed: {
    ...mapState([
      'newsPageType',
      'currentTabName',
    ]),
    ...mapGetters([
      'newsType',
    ]),
    // tableDataByCategory() {
    //   if (this.currentTabName === '全部' || this.currentTabName === '') {
    //     return this.tableData;
    //   }
    //   return this.tableData.filter((data) => data.category === this.currentTabName);
    // },
    // tableDataByDate() {
    //   if (this.start_date && this.end_date) {
    //     if (Date.parse(this.start_date) <= Date.parse(this.end_date)) {
    //       return this.tableDataByCategory.filter((data) => Date.parse(this.start_date)
    //       <= Date.parse(data.date)
    //       && Date.parse(data.date) <= Date.parse(this.end_date));
    //     }
    //     alert('日期區間選擇錯誤！請重新選擇！');
    //     return this.tableDataByCategory;
    //   }
    //   return this.tableDataByCategory;
    // },
    // tableDataByKeyword() {
    //   if (this.keyword) {
    //     return this.tableDataByDate.filter((data) => data.title.includes(this.keyword)
    //     || data.content.includes(this.keyword));
    //   }
    //   return this.tableDataByDate;
    // },
    // tableDataPerPage() {
    //   const start = (this.nowPage - 1) * this.perPage;
    //   if (this.tableDataByKeyword.length < this.perPage) {
    //     return this.tableDataByKeyword;
    //   }
    //   return this.tableDataByKeyword.slice(start, start + this.perPage);
    // },
  },
  methods: {
    ...mapMutations([
      'toggleModalInfo',
      'updateModalInfoContent',
      'updateNewsType',
    ]),
    ...mapActions([
      'setSysVars',
    ]),
    postDate(row) {
      const date = _.get(row, 'start_date', '');
      if (date) {
        return moment(date).format('YYYY-MM-DD');
      }
      return '';
    },
    /**
     * DatePick：關閉未來日期選取
     */
    isFutureDate(date) {
      const endDate = new Date();
      return date > endDate;
    },
    /**
     * API Call：Article Index
     */
    filterData(searchParams, isInit) {
      /** 檢查時間必選 */
      if (this.params.start_date && this.params.end_date) {
        /** 檢查時間先後順序：前早於後 */
        if (Date.parse(this.params.start_date) <= Date.parse(this.params.end_date)) {
          articleIndex({
            ...searchParams,
            type: 'news',
          }).then((result) => {
            this.tableData = result.data;
            this.totalPage = result.pageCount;
            this.params.page = searchParams.page;
            if (isInit) {
              this.$router.replace({
                query: {
                  start_date: this.params.start_date,
                  end_date: this.params.end_date,
                  keyword: this.params.keyword,
                  num: searchParams.page,
                },
              }).catch((err) => {
                if (err.name !== 'NavigationDuplicated') throw err;
              });
            } else {
              this.$router.push({
                query: {
                  start_date: this.params.start_date,
                  end_date: this.params.end_date,
                  keyword: this.params.keyword,
                  num: searchParams.page,
                },
              }).catch((err) => {
                if (err.name !== 'NavigationDuplicated') throw err;
              });
            }
          });
        /** 若時間前晚於後則警告 */
        } else {
          this.updateModalInfoContent('時間選擇錯誤');
          this.toggleModalInfo(true);
        }
      /** 時間空白則警告 */
      } else {
        this.updateModalInfoContent('請選擇時間');
        this.toggleModalInfo(true);
      }
    },
    /**
     * 根據頁籤對應的sys_var_id篩選
     */
    filterById(page = 1, isInit) {
      /** 預設篩選資料（撈全部時，不送 sys_var_id） */
      if (this.newsPageType === '全部' || this.newsPageType === undefined
      ) {
        this.filterData({ ...this.params, page }, isInit);
      } else {
        for (let i = 0; i < this.newsType.length; i += 1) {
          const item = this.newsType[i];
          if (item.name === this.newsPageType) {
            this.sys_var_id = item.id;
            this.filterData({ ...this.params, page, sys_var_id: this.sys_var_id }, isInit);
            return;
          }
        }
      }
    },
    /**
     * 關鍵字搜尋時，按Enter可觸發篩選API
     */
    updateKeyword() {
      this.params.keyword = this.placeHolder;
      this.filterById();
    },
    /**
     * 分頁器：換頁功能
     */
    updatePage(value) {
      // this.params.page = value;
      // this.$router.push({ query: { num: value } });
      this.filterById(value);
    },
  },
  components: {
    DatePick,
    Pagination,
    // PaginationComputed,
  },
};
</script>
